body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 580px) {
  .md-page-1{
    width:100% !important;
    margin: auto;
  }
  .mobile_view{
    width: 100% !important;
  }
  .ishaarat_display{
    display: none !important;
    width: 100% !important;
  }
  .padding-left{margin: 0 !important;}
.reading_container3{
  font-size: 24px !important;
}
.punctuation{padding: 0px !important;}
}
@media only screen and (max-width:550px){
  .menu-container{
   display: none !important;
  }
  .mobile_view{
    width: 100%;
    margin: auto !important;
  }
  .padding-left{margin: 0 !important;}
}
@media only screen and (max-width: 550px) {
  .md-page-1{
    width:100% !important;
    margin: auto;
  }
  .mobile_view{
    width: 100% !important;
  }
  .ishaarat_display{
    display: none !important;
    width: 100% !important;
  }
  .padding-left{margin: 0 !important;}
.reading_container3{
  font-size: 20px !important;
}
.punctuation{padding: 0px !important;}
}

@media only screen and (min-width: 550px) {
  .md-page-1{
    width:100% !important;
    margin: auto;
    
  }
  .padding-left{margin: 0 !important;}
  .ishaarat_display{
    width: 100% !important;
    display: none !important;
  }
  .punctuation {
    padding: 0px 5px 0px 0px !important;
}
.reading_container3{
  font-size: 26px !important;
}
}
.reading_container3{
  font-size: 52px;
}

@media only screen and (min-width: 560px) {
  .md-page-1{
    width:100% !important;
    margin: auto;
    
  }
  .padding-left{margin: 0 !important;}
  .ishaarat_display{
    width: 100% !important;
  }
  .punctuation {
    padding: 0px 5px 0px 0px !important;
}
.reading_container3{
  font-size: 22px !important;
}
}
.reading_container3{
  font-size: 52px;
}
@media only screen and (min-width: 580px) {
  .reading_container3{
    font-size: 24px !important;
  }
  .padding-left{margin: 0 !important;}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .reading_container3{
    font-size: 30px !important;
  }
  .mobile_view{
    width: 80% !important;
  }
  .md-page-1{
    /* width:730px !important; */
  }
}

@media only screen and (min-width: 800px) {
  .reading_container3{
    font-size: 34px !important;
  }
  .punctuation{
    padding: 0 !important;
  }
  .mobile_view{
    width: 80% !important;
  }
  .md-page-1{
    /* width:730px !important; */
  }
}
@media only screen and (min-width: 880px) {
  .reading_container3{
    font-size: 38px !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .reading_container3{
    font-size: 48px !important;
  }
  .smallfont{
    /* margin: 7px !important; */
  }
  .md-page-1{
    /* width:830px !important; */
  }
}
@media only screen and (min-width:600px){
  .ishaarat_display{
    display: block !important;
  }
}
@media only screen and (min-width: 1080px) {
  .reading_container3{
    font-size: 48px !important;
  }
  .smallfont{
    /* margin: 7px !important; */
  }
}

.padding-left{
  margin-left: 55px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .md-page-1{
    /* width:1100px !important; */
  }
  .smallfont{
    margin: 7px !important;
  }
  .reading_container3{
    font-size: 60px !important;
  }
}
.mobile_view{
  float: left;
  margin-left: 55px;
}
.md-page-1{
  width:100%;
}

@media only screen and (min-width: 1480px) {
  .reading_container3{
    font-size: 66px !important;
  }
  .smallfont{
    margin: 7px !important;
  }
}

@media (max-width:2000px){
  .md-page{
    width:720px !important;
  }

  .md-page2{
    width:100% !important;
  }
  
}
@media (max-width:900px){
  .md-page{
    width:720px !important;
  }
  .md-page2{
    width:780px !important;
  }
  #reading_container{
    font-size: 36px !important;
  }

}
@media (max-width:820px){
  .md-page{
    width:720px !important;
  }

  .md-page2{
    width:780px !important;
  }
  #reading_container2{
    font-size: 48px !important;
  }
 

}

@media (max-width:750px){
  .md-page{
    width:720px !important;
  }
  .md-page2{
    width:680px !important;
  }
  #reading_container2{
    font-size: 30px !important;
  }
  
  .page-container{
    padding: 10px 0px 0px 0px;
  }
 
}

@media (max-width:680px){
  .md-page{
    width:580px !important;
  }
  .md-page2{
    width:640px !important;
  }
  .page-container{
    padding: 10px 0px 0px 0px;
  }
  #reading_container{
    font-size: 36px !important;
  }
  #reading_container2{
    font-size: 28px !important;
  }
  .quran-page.shadow-lg {
    border-image: url(/static/media/frame-color.0c3ea90c.png) 85 round;
    border-width: 20px !important;
    padding: 0px;
}
.quran-page2.shadow-lg {
  border-image: url(/static/media/frame-color.0c3ea90c.png) 85 round;
  border-width: 20px !important;
  padding: 0px;
}

}

@media (max-width:580px){
  .md-page{
    width:490px  !important;
  }
  #reading_container{
    font-size: 26px !important;
  }
  .md-page2{
    width:490px  !important;
  }
  #reading_container2{
    font-size: 22px !important;
  }
  
  /* .smallfont{font-size:2.5vw !important;} */
  .quran-page.shadow-lg {
    border-image: url(/static/media/frame-color.0c3ea90c.png) 85 round;
    border-width: 20px !important;
    padding: 0px;
}
.quran-page2.shadow-lg {
  border-image: url(/static/media/frame-color.0c3ea90c.png) 85 round;
  border-width: 20px !important;
  padding: 0px;
}
}

@media (max-width:480px){
  .md-page{
    width:100%  !important;
  }
  .md-page2{
    width:100%  !important;
  }
  #reading_container{
    font-size: 20px !important;
  }
  #reading_container2{
    font-size: 14px !important;
  }
  /* .smallfont{font-size:2.5vw !important;} */
  .quran-page.shadow-lg {
    border-image: url(/static/media/frame-color.0c3ea90c.png) 85 round;
    border-width: 20px !important;
    padding: 0px;
}

.quran-page2.shadow-lg {
  border-image: url(/static/media/frame-color.0c3ea90c.png) 85 round;
  border-width: 20px !important;
  padding: 0px;
}
}
@media (max-width:380px){
  .md-page{
    width:100%  !important;
  }
  #reading_container{
    font-size: 18px !important;
  }
  .md-page2{
    width:100%  !important;
  }
  #reading_container2{
    font-size: 12px !important;
  }
  /* .smallfont{font-size:2.5vw !important;} */
  .quran-page.shadow-lg {
    border-image: url(/static/media/frame-color.0c3ea90c.png) 85 round;
    border-width: 20px !important;
    padding: 0px;
}
}
.ishaarat_display{
  display: block;
}
#reading_container{
  font-size: 43px ;
}
#reading_container3{
  font-size: 60px ;
}

#reading_container2{
  font-size: 82px ;
}
.quran-page2 .ayat_container {
  padding: 2px !important;
}
 
.md-page{
  width: 730px;
  margin: auto;
}
.md-page2{
  width: 840px;
  margin: auto;
}
.article, p {
  
  line-height: 1.6;
  font-weight: 300;
}
.input-class {
  text-align: center;
  width: 60%;
  padding: 7px;
  margin: 2px 0px 0px 0px;
}
.padding-10{
  padding: 10px;
}
.tenseDisplay{
  text-align: center;
  color: blue;
  font-size: 2.5vw;
  vertical-align:bottom;
}
.margin-5{
  margin: 1%;
}
.switch{
  text-align: center;
  padding: 2px;
}
.baabinheader {
  font-family: Majidi;
  font-size: 36px;
  color: blue;
  display: inline-block;
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ayat_container .wordsdisplay {
  /* padding: 5px; */
}
.flex-container{
     display: flex;
    /* border-bottom: solid 1px; */
    justify-content: start;
    /* line-height: 141% !important; */
    display: flex;
}
.flex-container2{
  display: flex;
  /* border-bottom: solid 1px; */
  justify-content: space-between;
  line-height: 130%;
  display: flex;
  flex-wrap: nowrap;
}
.flex-container a{
  padding: 0;
}
.ssssss {
  max-width:930px
 }
.punctuation{
  /* font-size: 2.5vw; */
  font-family: 'Majidi' !important;
  vertical-align:bottom ;
  padding: 14px 25px 0px 5px
}
/* .flex-container span:first-of-type{
  width: auto;
}
.flex-container span:last-child{
  width: 100%;
} */
.ayat_container{
  
  /* padding: 5px; */
  color: #000;
  /* width: 100%; */
}
.highlight{
  background-color: khaki;
}
.highlight{
  background-color: khaki;
}

.custom{
  display:flex;flex-wrap:wrap;border-bottom:solid 1px;
  justify-content: space-around !important;
}
.ishc {
  float: right;
  width: 23%;
  padding-right: 1%;
  height: 100%;
  top: 0%;
  background: #DFDFDF;
}
.ishaara {
  font-family: Alvi Nastaleeq;
  font-size: 1.5vw;
  padding: 2%;
  color: black;
  position: absolute;
  margin-top: 14%;
  /* width: 180px; */
}
.w-100 {
 box-shadow: 0 0 0 1rem rgb(145, 201, 230);
 border-radius: 12px;
 outline-offset: 0.5rem;
 border: 0.5rem outset rgb(182, 186, 221);
 outline: 0.5rem solid rgb(236, 232, 193);
 border-radius: 12px;
}
.sarf-custom-header{
  display: flex;
  flex-wrap: wrap;
  border: solid 1px;
  justify-content: space-around !important;
  height: 38px;
  background: forestgreen;
  color: white;
  font-size: 28px;
  
}

.switch.btn {
  width: 100%;
  padding: 20px;
}
/*


    .ayat_container{
      display:flex;flex-wrap:wrap;border-bottom:solid 1px;
      justify-content:  space-between !important;
      width:100%;
      padding: 5px;
      clear: both;
    }
*/
.btn-success{background:forestgreen;color:white}
.btn-light{background:gray;color:white}
a {
  text-decoration: none;
  display: inline-block;
  padding: 9px 15px;
}

a:hover {
  background-color: #ddd;
  color: black;
}
.switch-on{top:8px !important;}
.switch-off{top:8px !important;}
.switch-group{width: 165%;}
.previous {
  background-color: #f1f1f1;
  color: black;
}
.qc_urdufontsize{
  border-bottom: 1px solid;
  border-top: 1px solid;
    text-align: center;
    background-color: #00FFCC;
    color: black;
    font-family: Alvi Nastaleeq !important;
    direction: ltr;
    font-size: 2.7vw;
    padding: 10px;
}
.ayat_translation_font{
  
    text-align: center;
    color:white;
    font-family: Alvi Nastaleeq !important;
    direction: ltr;
    font-size: 1.2rem;
    padding: 10px;
}
.page-font-size{
  font-size: 2.5vw;
}
.qc_font{
  text-align: center;
  background-color: #00FFCC;
  color: black;
  direction: rtl;
  font-size: 2.7vw;
  font-family: 'Majidi';
}
.next {
  background-color: #4CAF50;
  color: white;
  margin: 2px 2px 0px -4px;
}

.round {
  border-radius: 50%;
}
@font-face
{
  font-family:Hafs;
  font-style:normal;
  font-weight:400;
  src:url(/static/media/Uthmanic-HAFS.b089297b.eot?#iefix) format('embedded-opentype'),
  url(/static/media/Uthmanic-HAFS.db77eec6.woff2) format('woff2'),
  url(/static/media/Uthmanic-HAFS.23263f96.woff) format('woff'),
  url(/static/media/Uthmanic-HAFS.84523c4f.ttf) format('truetype')
}
@font-face {font-family: "Hafs"; 
  src: url("//db.onlinewebfonts.com/t/2c1ce06a505728976c1d2993407312b5.eot"); 
  src: url("//db.onlinewebfonts.com/t/2c1ce06a505728976c1d2993407312b5.eot?#iefix") format("embedded-opentype"), 
  url("//db.onlinewebfonts.com/t/2c1ce06a505728976c1d2993407312b5.woff2") format("woff2"), 
  url("//db.onlinewebfonts.com/t/2c1ce06a505728976c1d2993407312b5.woff") format("woff"), 
  url("//db.onlinewebfonts.com/t/2c1ce06a505728976c1d2993407312b5.ttf") format("truetype"), 
  url("//db.onlinewebfonts.com/t/2c1ce06a505728976c1d2993407312b5.svg#KFGQPC Uthmanic Script HAFS") format("svg"); }
@font-face
{
    font-family: Al_Mushaf;
    src: url(/static/media/Al_Mushaf.911b0c80.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Al_Mushaf.8327ec18.woff) format('woff'),
        url(/static/media/Al_Mushaf.8ee88e2a.ttf) format('truetype');
}

@font-face
{
    font-family: Al_Qalam;
    src:url(/static/media/Al_Qalam.d24b8a52.ttf) format('truetype');
}

@font-face
{
    font-family: Alvi Nastaleeq;
    src: url(/static/media/alvi_Nastaleeq_Lahori_shipped.d3045112.eot?#iefix) format('embedded-opentype'),
        url(/static/media/alvi_Nastaleeq_Lahori_shipped.a0e50dff.ttf) format('truetype');
}

@font-face
{
    font-family: Majidi;
    src: url(/static/media/Majidi_v5.536d4540.ttf);
}
div .container{margin-top:50px}
.direction{
  direction: rtl;
}
.punctuationwordOnMushaf {
  font-family: 'Majidi' !important;
}

  .sarf_top{
    padding: 30px;
    background-color:#00FFCC;
    border: solid 1px;
  }
 .sarf_sagheer{
   cursor: pointer;
 }
 .sarf_page_style .pure-table{
   width:100%
 }
 .sarf_page_style .pure-table th{
  font-size: 1.5vw;
    font-family: 'Al_Mushaf' !important;
    text-align: center;
    background-color: darkgreen;
    color: white;
    font-weight: 100;
}
.pure-g {
  text-rendering: optimizeLegibility;
  font-weight: 100;
}
.arabic {
  font-size: 2.5vw;
    font-family: 'Majidi' !important;
    text-align: center;
    font-weight: 100;
}
.highlighted_word_in_example_ayat{
  color:red;
}
.highlighted_word_in_more_ayat{
  color:yellow;
}
.pure-table th td{
  font-family: 'Al_Qalam' !important;
}

.sarf_page_style .pure-table td{
  font-size: 2.9vw;
  font-family: 'Al_Qalam'  !important;
  text-align: center;
  background-color: yellow;
  color: brown;
}
.Modal-module_modalBody__3_RoW {
  padding: 10px;
}
.Modal-module_modalTitle__1TtY9 {
background-color: gray;
color: white  !important;
padding: 15px !important;
font-size: 34px !important;
font-family: 'Majidi';
text-align: center;
text-transform: capitalize !important;
}
 .moreLink{
   font-size: 18px;
   text-align: end;
   font-weight: bold;
   vertical-align: bottom;
 }
 .ishaaraa-container{
  background-color: rgb(226 208 226);
  text-align: left;
 }
 
 .reading-page{
  background-color: white;
 }
 .quran-page.shadow-lg {
  background-size: 100%;
    background-repeat: no-repeat;
    line-height: 1.75;
    border-image: url(/static/media/frame-color.0c3ea90c.png)100 round;
    border-width:40px;

}
.quran-page2.shadow-lg {
  background-size: 100%;
    background-repeat: no-repeat;
    line-height: 1.75;
    border-image: url(/static/media/frame-color.0c3ea90c.png)100 round;
    border-width:40px;

}
.quran-page {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  
}
.quran-page2 {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: normal;
  /* text-align: right; */
  
}
.p440-v2 {
    font-family: 'Al_Mushaf' !important;
  }
.page-container{
  margin-top: 0px;
  padding: 10px;
  margin-bottom: 40px;
  background-color: #e9f0f9;
}

  .p440-v1 {
    font-family: 'Majidi' !important;
  }
  .p440-v3 {
    font-family: 'Hafs' !important;
  }
  .pointers{border: solid 1px grey;text-align: center; font-size: 18px;}
  .text-direction{
    direction: rtl;
  }
  .wordsdisplay{
    display: inline-block;
    vertical-align: sub;
    color: #444;
    /* padding: 5px; */
  }
  
.moreayat tr:nth-child(2n+1) {
  color: white;
}
#moreInfo .ayats {
  font-size: 36px;
}
.moreayat tr:nth-child(2n+1) {
  background-color: #166c93;
  color: white;
}
.moreayat {

font-size: 34px;
}
.moreayat tr:nth-child(2n) {
  background-color: #FFF7DB;
}
.moreayat .trans {
  font-family: Alvi Nastaleeq;
  font-size: 2.5vw;
  padding-top: 2px;
  padding-bottom: 2px;
}
.moreayat, .moreayat th, .moreayat td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}
.moreayat .arabic > td {
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  text-rendering: optimizespeed;
  cursor: pointer;
}
  .smallfont{
    font-family: 'Majidi';
    color:black;
    cursor: pointer;
    vertical-align: -webkit-baseline-middle;
  }
  .article{
    line-height: 1.6;
    font-weight: 300;
  }
  .nonmushafrow{
    font-size: 30px;
    color: #444;
    display: flex;
    padding: 5px;
  }
  .quranText {
    direction: rtl;
    font-size: 1.15em;
    text-align: right;
    width: 100%;
    margin: 0px 30px;
    padding: 16px 14px;
    color: black;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.qty .count {
  color: #000;
  display: inline-block;
  vertical-align: top;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  padding: 0 2px
  ;min-width: 35px;
  text-align: center;
}
.qty .plus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 30px;
  height: 30px;
  font: 30px/1 Arial,sans-serif;
  text-align: center;
  border-radius: 50%;
  }
.qty .minus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 30px;
  height: 30px;
  font: 30px/1 Arial,sans-serif;
  text-align: center;
  border-radius: 50%;
  background-clip: padding-box;
}
.qty input{width:50%}
.custom-restricted-width {
  /* To limit the menu width to the content of the menu: */
  display: inline-block;
  /* Or set the width explicitly: */
  /* width: 10em; */
}

/* Make the navigation 100% width on phones */
#nav {
  width: 100%;
  height: 40px;
  position: relative;
  background: rgb(37, 42, 58);
  text-align: center;
}
/* Show the "Menu" button on phones */
#nav .nav-menu-button {
  display: block;
  top: 0.5em;
  right: 0.5em;
  position: absolute;
}

/* When "Menu" is clicked, the navbar should be 80% height */
#nav.active {
  height: 80%;
}
/* Don't show the navigation items... */
.nav-inner {
  display: none;
}

/* ...until the "Menu" button is clicked */
#nav.active .nav-inner {
  display: block;
  padding: 2em 0;
}


/*
* -- NAV BAR STYLES --
* Styling the default .pure-menu to look a little more unique.
*/
#nav .pure-menu {
  background: transparent;
  border: none;
  text-align: left;
}
  #nav .pure-menu-link:hover,
  #nav .pure-menu-link:focus {
      background: rgb(55, 60, 90);
  }
  #nav .pure-menu-link {
      color: #fff;
      margin-left: 0.5em;
  }
  #nav .pure-menu-heading {
      border-bottom: none;
      font-size:110%;
      color: rgb(75, 113, 151);
  }
  .jp-jplayer {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  @media (max-width: 630px) {
    .jp-jplayer .jp-state-full-screen .jp-poster {
      height: auto;
    }
  }
  .jp-hidden {
    display: none;
  }
  .jp-state-no-volume-support .jp-mute,
  .jp-state-no-volume-support .jp-volume-bar,
  .jp-state-no-volume-support .jp-volume-bar-value {
    display: none;
  }
  .jp-jplayer.jp-state-full-screen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    visibility: visible;
    position: fixed;
  }
  .jp-jplayer.jp-state-full-screen .jp-gui {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .jp-jplayer.jp-state-full-screen .jp-gui .jp-poster {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    z-index: -1;
    margin: 0 auto;
    position: fixed;
    display: inline-block;
  }
  .jp-state-audio.jp-jplayer.jp-state-full-screen .jp-media-container,
  .jp-state-video.jp-jplayer.jp-state-full-screen .jp-media-container {
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  .jp-state-audio.jp-jplayer.jp-state-full-screen .jp-media-container video,
  .jp-state-video.jp-jplayer.jp-state-full-screen .jp-media-container video {
    width: 100%;
  }
  @media (max-width: 400px) {
    .jp-sleek.jp-jplayer.jp-state-no-volume-support .jp-gui .jp-controls .jp-repeat {
      display: none;
    }
  }
  @media (max-width: 480px) {
    .jp-sleek.jp-jplayer.jp-state-no-volume-support .jp-gui .jp-controls .jp-download {
      display: none;
    }
  }
  @media (max-width: 700px) {
    .jp-sleek.jp-jplayer.jp-state-no-volume-support .jp-gui .jp-controls .jp-title {
      display: none;
    }
  }
  @media (max-width: 530px) {
    .jp-sleek.jp-jplayer.jp-state-no-volume-support:not(.jp-state-full-screen) .jp-gui .jp-controls .jp-poster {
      display: none;
    }
  }
  @media (max-width: 354px) {
    .jp-sleek.jp-state-video:not(.jp-state-no-volume-support) .jp-gui .jp-controls .jp-volume-container {
      display: none;
    }
  }
  @media (max-width: 450px) {
    .jp-sleek.jp-state-video:not(.jp-state-no-volume-support) .jp-gui .jp-controls .jp-repeat {
      display: none;
    }
  }
  @media (max-width: 530px) {
    .jp-sleek.jp-state-video:not(.jp-state-no-volume-support) .jp-gui .jp-controls .jp-download {
      display: none;
    }
  }
  @media (max-width: 700px) {
    .jp-sleek.jp-state-video:not(.jp-state-no-volume-support) .jp-gui .jp-controls .jp-title {
      display: none;
    }
  }
  @media (max-width: 530px) {
    .jp-sleek.jp-state-video:not(.jp-state-no-volume-support):not(.jp-state-full-screen) .jp-gui .jp-controls .jp-poster {
      display: none;
    }
  }
  @media (max-width: 400px) {
    .jp-sleek.jp-jplayer.jp-state-audio:not(.jp-state-no-volume-support) .jp-gui .jp-controls .jp-full-screen {
      display: none;
    }
  }
  @media (max-width: 450px) {
    .jp-sleek.jp-jplayer.jp-state-audio:not(.jp-state-no-volume-support) .jp-gui .jp-controls .jp-repeat {
      display: none;
    }
  }
  @media (max-width: 530px) {
    .jp-sleek.jp-jplayer.jp-state-audio:not(.jp-state-no-volume-support) .jp-gui .jp-controls .jp-download {
      display: none;
    }
  }
  @media (max-width: 750px) {
    .jp-sleek.jp-jplayer.jp-state-audio:not(.jp-state-no-volume-support) .jp-gui .jp-controls .jp-title {
      display: none;
    }
  }
  @media (max-width: 580px) {
    .jp-sleek.jp-jplayer.jp-state-audio:not(.jp-state-no-volume-support):not(.jp-state-full-screen) .jp-gui .jp-controls .jp-poster {
      display: none;
    }
  }
  .jp-sleek {
    font-family: "Source Sans Pro", sans-serif;
  }
  .jp-state-video:not(.jp-state-idle).jp-sleek .jp-poster,
  .jp-state-video.jp-state-playing.jp-sleek .jp-poster {
    display: none;
  }
  .jp-state-video.jp-sleek .jp-media-container {
    position: relative;
  }
  .jp-state-video.jp-sleek .jp-media-container .jp-poster {
    max-height: 100%;
    max-width: 100%;
  }
  .jp-state-video.jp-sleek .jp-media-container {
    overflow: hidden;
    display: table;
    margin: 0 auto;
  }
  .jp-state-video.jp-sleek .jp-media-container video {
    display: block;
  }
  .jp-state-video.jp-sleek .jp-media-container .jp-poster {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .jp-sleek .jp-gui {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 42px;
    background-color: #e5e5e5;
    margin-right: -10px;
  }
  :not(.jp-state-full-screen).jp-sleek .jp-gui {
    z-index: 100;
  }
  .jp-state-no-browser-support.jp-sleek .jp-gui {
    height: auto;
  }
  .jp-state-no-browser-support.jp-sleek .jp-gui .jp-controls {
    display: none;
  }
  .jp-sleek .jp-gui .jp-no-browser-support {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    padding: 4px;
  }
  .jp-sleek .jp-gui .jp-no-browser-support h4 {
    font-weight: bold;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 6px;
  }
  .jp-sleek .jp-gui .jp-controls {
    height: inherit;
    position: relative;
    font-size: 24px;
    white-space: nowrap;
    display: table;
    border-spacing: 5px 0;
    margin: 0 auto;
  }
  .jp-sleek .jp-gui .jp-controls button {
    font-size: inherit;
  }
  .jp-sleek .jp-gui .jp-controls .jp-title {
    font-size: 12px;
    font-weight: bold;
    max-width: 230px;
    min-width: 230px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
  }
  .jp-state-audio.jp-sleek .jp-gui .jp-controls .jp-title {
    margin-left: 10px;
  }
  .jp-state-audio.jp-sleek .jp-gui .jp-controls .jp-poster {
    max-height: 100%;
    height: 100%;
    vertical-align: middle;
  }
  .jp-sleek .jp-gui .jp-controls > * {
    vertical-align: middle;
    height: inherit;
    display: table-cell;
  }
  .jp-sleek .jp-gui .jp-controls .jp-repeat {
    margin-left: 12px;
    margin-right: 12px;
  }
  .jp-sleek .jp-gui .jp-controls .jp-progress {
    background-color: #a1c1f4;
    width: 500px;
    min-width: 180px;
    max-width: 500px;
    font-size: 20px;
    position: relative;
  }
  .jp-sleek .jp-gui .jp-controls .jp-progress .jp-current-time,
  .jp-sleek .jp-gui .jp-controls .jp-progress .jp-duration {
    padding: 0 12px;
    position: relative;
    display: inline-block;
  }
  .jp-sleek .jp-gui .jp-controls .jp-progress .jp-duration {
    right: 0;
    position: absolute;
  }
  .jp-sleek .jp-gui .jp-controls .jp-progress .jp-seek-bar {
    cursor: pointer;
    height: 100%;
  }
  .jp-sleek .jp-gui .jp-controls .jp-progress .jp-seek-bar .jp-current-time,
  .jp-sleek .jp-gui .jp-controls .jp-progress .jp-seek-bar .jp-duration {
    top: 50%;
    transform: translateY(-50%);
  }
  .jp-sleek .jp-gui .jp-controls .jp-progress .jp-seek-bar .jp-play-bar {
    background-color: #71a6fc;
    height: 100%;
    position: absolute;
  }
  .jp-sleek .jp-gui .jp-controls .jp-progress .jp-seek-bar .jp-buffer-bar {
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
  }
  .jp-sleek .jp-gui .jp-controls .jp-volume-container {
    min-width: 42px;
    position: relative;
  }
  .jp-sleek .jp-gui .jp-controls .jp-volume-container:hover .jp-volume-slider .jp-volume-bar-container {
    height: 100px;
    transition-duration: 0.3s;
    outline: solid 1px #cfcfcf;
  }
  .jp-sleek .jp-gui .jp-controls .jp-volume-container:hover .jp-volume-slider .jp-volume-bar-container .jp-volume-bar {
    display: block;
    bottom: 10%;
  }
  .jp-sleek .jp-gui .jp-controls .jp-volume-container .jp-mute {
    height: inherit;
  }
  .jp-state-no-volume-support.jp-sleek .jp-gui .jp-controls .jp-volume-container {
    display: none;
  }
  .jp-sleek .jp-gui .jp-controls .jp-volume-container .jp-volume-slider {
    position: absolute;
    top: 0;
    height: 100%;
  }
  .jp-sleek .jp-gui .jp-controls .jp-volume-container .jp-volume-slider .jp-volume-bar-container {
    background-color: #e5e5e5;
    position: absolute;
    height: 0;
    width: 24px;
    left: 8px;
    bottom: 100%;
    transition-duration: 0.3s;
  }
  .jp-sleek .jp-gui .jp-controls .jp-volume-container .jp-volume-slider .jp-volume-bar-container .jp-volume-bar {
    background-color: #a1c1f4;
    cursor: pointer;
    border-radius: 1px;
    display: none;
    transform: translateX(-50%) rotate(180deg);
    width: 8px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    left: 50%;
  }
  .jp-sleek .jp-gui .jp-controls .jp-volume-container .jp-volume-slider .jp-volume-bar-container .jp-volume-bar .jp-volume-bar-value {
    background-color: #71a6fc;
    border-radius: inherit;
  }
  .jp-icon-controls button:focus {
    outline: none;
  }
  .jp-icon-controls button {
    border: none;
    background-color: transparent;
  }
  .jp-icon-controls .jp-download,
  .jp-icon-controls .jp-play,
  .jp-icon-controls .jp-repeat,
  .jp-icon-controls .jp-full-screen,
  .jp-icon-controls .jp-mute {
    min-width: 42px;
    color: #000;
    padding: 0;
  }
  .jp-icon-controls a {
    cursor: pointer;
    text-decoration: none;
    color: #000;
    text-align: center;
    min-width: 42px;
  }
  .jp-jplayer .jp-icon-controls .jp-play i:before {
    content: "\F04B";
  }
  .jp-jplayer.jp-state-playing .jp-icon-controls .jp-play i:before {
    content: "\F04C";
  }
  .jp-jplayer.jp-state-muted .jp-icon-controls .jp-mute i:before {
    content: "\F026";
  }
  .jp-jplayer.jp-state-volume-low .jp-icon-controls .jp-mute i:before {
    content: "\F027";
  }
  .jp-jplayer.jp-state-volume-high .jp-icon-controls .jp-mute i:before {
    content: "\F028";
  }
  .navbar-container {
    position: relative;
}
.navbar {
  z-index: 1020;
  width: 100%;
}
.dropdown-navbar {
  max-width: 400px;
  left: auto;
  right: 0;
}
.dropdown-navbar {
  padding: 0;
  border: 0;
  box-shadow: 0 4px 6px rgba(30, 30, 100, 0.25)!important;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  background-clip: padding-box;
}
.dropdown-menu > li {
  padding: 0 9px;
  position: relative;
}
.navbar {
  /* position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; */
}
.navbar>.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.container {
  position: relative;
  z-index: 1;
}
@media (min-width: 1200px){
.container {
    max-width: 1140px;
}
.navbar-nav>.nav-item {
  font-size: 1.25rem;
}
}
@media (min-width: 992px){
.container {
    max-width: 960px;
}
.order-lg-3 {
  order: 3;
}
.navbar-expand-lg .navbar-collapse {
  display: flex!important;
  flex-basis: auto;
}
.navbar-expand-lg .navbar-nav {
  flex-direction: row;
}
.mb-lg-0, .my-lg-0 {
  margin-bottom: 0!important;
}
.mt-lg-0, .my-lg-0 {
  margin-top: 0!important;
}
.justify-content-lg-end {
  justify-content: flex-end!important;
}
}
.container {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
}
.navbar-light .navbar-brand {
  color: rgba(0,0,0,.9);
}
.p-0 {
  padding: 0!important;
}
.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-left: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.align-items-center {
  align-items: center!important;
}
.d-flex {
  display: flex!important;
}
.navbar-collapse {
 
  flex-grow: 1;
  align-items: center;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-right: 0;
  margin-bottom: 0;
  list-style: none;
}
.menu-logo{
  max-width: 80%;
}
.menu-logo img{
  max-width: 80%;
  max-height: 50px;
}
.bg-primary-3, .bg-v14 {
  background: url(https://quranpedia.net/images/svg/v14-transparent.svg) center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-primary-3 {
  background-color: #002520!important;
}
.navbar-container{
  position: -webkit-sticky;
  position: sticky;
}
.other_baab_modal_width{
  width: 80% !important;
    max-width: 80% !important;
    height: 100%;
}

.translation_modal_container .Modal-module_modalBody__3_RoW{
  background-color: #4CAF50;
  color:white;
  text-align: center;
  font-family: Alvi Nastaleeq !important;
}
.translation_modal_container h3{
  display: none;
}

.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}
.ant-modal-content{
  background-color: green  !important;;
  color: white;
  font-size: 2.7rem  !important;
}
.translation_container{
  background-color: #4CAF50  !important;;
  color:white;
  text-align: center  !important;;
  font-family: Alvi Nastaleeq !important;
  font-size: 2.7rem  !important;;
}
.ant-modal-body{
  font-size: 30px  !important;;
}
.ant-modal-header {
  border-bottom: none !important;
  padding: 0 !important;
  color: green !important;
  background-color: green !important;
}
.ant-modal-title{
  font-weight: bold;
  color:white !important;
}
.ant-modal{
  padding-bottom: 0 !important;
} 
.ant-modal-body {
  padding: 5px !important;
  font-size: 22px !important;
}
.navbar {
  padding-left: 0;
  padding-right: 15px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 990;
  border: 0;
  min-height: 40px;
  height: 50px;
  border-radius: 0;
  background-color: #58b1fc;
}
.flaty-nav {
  height: 100%;
  padding-left: 10px;
}
.pull-right {
  float: right!important;
}
.nav {
  margin-bottom: 0;
  list-style: none;
}
ul, ol {
  margin-top: 0;
}
.flaty-nav > li:first-child, .flaty-nav > li.no-border {
  border-left: none;
}
.flaty-nav > li {
  line-height: 40px;
  max-height: 40px;
  padding: 0;
  position: relative;
  display: inline-block !important;
}
.flaty-nav .user-profile {
  padding-right: 0;
  white-space: nowrap;
}
#sidebar {
  width: 200px !important;
  position: absolute;
  color:white;
}
@media (min-width: 768px){
.navbar-collapse.collapse {
    display: block!important;
    height: auto!important;
    padding-bottom: 0;
    overflow: visible!important;
}

}
.container > .navbar-header, .container > .navbar-collapse {
  margin-left: 0;
  margin-right: 0;
}
.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
  border-top: 0;
  max-height: 500%;
}
#sidebar:before, .nav-list .search-form, .nav-list .search-pan input, #nav-horizontal.nav-xs > li > a, .nav-list > li > a {
  background-color: #248dc1;
}
#sidebar:before {
  content: "";
  display: block;
  width: 200px;
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: 0;
}
.navbar-collapse:after {
  clear: both;
}
.navbar-collapse:before, .navbar-collapse:after {
  display: table;
  content: " ";
}
.nav-list > li {
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
}
.sidebar-collapsed .nav-list > li > a {
  padding: 5px 16px 0 7px !important;
}
.sidebar-collapsed .nav-list > li > a {
  position: relative;
}

.sidebar-collapsed .nav-list > li > a {
    padding: 5px 16px 0 7px !important;
}
.sidebar-collapsed .nav-list > li > a {
    position: relative;
}
li span{
  padding: 5px;
}
.nav-list .search-form {
  margin-top: 16px;
  margin-bottom: 16px;
}
.nav-list .search-pan {
  height: 38px;
}
.search-pan {
  z-index: 1100 !important;
  padding: 5px;
}
#sidebar:before, .nav-list .search-form, .nav-list .search-pan input, #nav-horizontal.nav-xs > li > a, .nav-list > li > a {
    background-color: #248dc1;
}
.nav-list > li > a:focus, .nav-list > li > a:hover {
  background-color: #0e6390!important;
}
.nav-list > li > a {
  display: block;
  height: 38px;
  line-height: 36px;
  margin: 0;
  padding: 0 16px 0 7px!important;
  color: #fff!important;
  text-shadow: none!important;
  font-size: 13px;
  text-decoration: none;
  width: 200px;
}
.sidebar-collapsed .nav-list {
  overflow: visible !important;
}
#sidebar.sidebar-collapsed {
  width: 42px;
}
.dropdown-toggle {
  z-index: 1101 !important;
}
.nav-list > li > a .arrow {
  display: inline-block;
  width: 14px!important;
  height: 14px;
  line-height: 14px;
  text-shadow: none;
  font-size: 18px;
  padding: 0;
  float: right;
  margin-top: 11px;
}
.navbar .nav > li > a, .navbar .nav > li > a:hover, .navbar .nav > li > a:focus, .dropdown-navbar > li.nav-header, .flaty-nav > li > a > [class*="fa-"], .navbar .navbar-brand, .nav-list .search-pan input, .nav-list .search-pan button > i, .nav-list > li > a, .nav-list > li > a:focus, .nav-list > li > a:hover, .nav-list > li > a .arrow, .nav-list > li a:hover > .arrow, .nav-list > li.active > a > .arrow, .nav-list > li.open > a > .arrow, .nav-list > li.open > a, .nav-list > li.active > a, .nav-list > li.active > a:hover, .nav-list > li.active > a:focus, .nav-list > li.active > a:active, .nav-list > li .submenu > li > a, .nav-list > li .submenu > li > span, .nav-list > li .submenu > li > ul > li > a, #nav-horizontal.nav-xs > li a, #sidebar-collapse > [class*="fa-"], .sidebar-collapsed .nav-list > li.open:hover > a {
  color: #fff;
}




.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}
.clearfix {
    *zoom: 1;
}

.menu-container {
    position: relative;
    margin: 0px auto;
    /* padding: 10px 0; */
    clear: both;
}





  
.mcd-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  background:#248dc1;
  height: 500px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  position: absolute;
  color: #fff;
  /* == */
  width: 60px;
  /* == */
}
.mcd-menu li {
  position: relative;
  /*float:left;*/
  /* width: 222px; */
}
.mcd-menu li a {
  display: block;
  text-decoration: none;
  padding: 12px 20px;
  color: #fff;
  /*text-align: center;
  border-right: 1px solid #E7E7E7;*/
  
  /* == */
  text-align: left;
  height: 36px;
  position: relative;
  /* border-bottom: 1px solid #EEE; */
  /* == */
}
.mcd-menu li a i {
  /*display: block;
  font-size: 30px;
  margin-bottom: 10px;*/
  
  /* == */
  float: left;
  font-size: 20px;
  margin: 0 10px 0 0;
  /* == */
  
}
/* == */
.mcd-menu li a p {
  float: left;
  margin: 0 ;
}
/* == */

.mcd-menu li a strong {
  display: block;
  text-transform: uppercase;
}
.mcd-menu li a small {
  display: block;
  font-size: 10px;
}

.mcd-menu li a i, .mcd-menu li a strong, .mcd-menu li a small {
  position: relative;
  
  transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -webkit-transition: all 300ms linear;
}
.mcd-menu li:hover > a i {
    opacity: 1;
    animation: moveFromTop 300ms ease-in-out;
}
.mcd-menu li:hover a strong {
    opacity: 1;
    animation: moveFromLeft 300ms ease-in-out;
}
.mcd-menu li:hover a small {
    opacity: 1;
    animation: moveFromRight 300ms ease-in-out;
}

.mcd-menu li:hover > a {
  color: #e67e22;
}
.mcd-menu li a.active {
  position: relative;
  color: #e67e22;
  border:0;
  /*border-top: 4px solid #e67e22;
  border-bottom: 4px solid #e67e22;
  margin-top: -4px;*/
  box-shadow: 0 0 5px #DDD;
  -moz-box-shadow: 0 0 5px #DDD;
  -webkit-box-shadow: 0 0 5px #DDD;
  
  /* == */
  border-left: 4px solid #e67e22;
  border-right: 4px solid #e67e22;
  margin: 0 -4px;
  /* == */
}
.mcd-menu li a.active:before {
  content: "";
  position: absolute;
  /*top: 0;
  left: 45%;
  border-top: 5px solid #e67e22;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;*/
  
  /* == */
  top: 42%;
  left: 0;
  border-left: 5px solid #e67e22;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  /* == */
}

/* == */
.mcd-menu li a.active:after {
  content: "";
  position: absolute;
  top: 42%;
  right: 0;
  border-right: 5px solid #e67e22;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
/* == */



.mcd-menu li ul,
.mcd-menu li ul li ul {
  position: absolute;
  height: auto;
  min-width: 200px;
  padding: 0;
  margin: 0;
  background: #248dc1;
  /*border-top: 4px solid #e67e22;*/
  opacity: 0;
  visibility: hidden;
  transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -webkit-transition: all 300ms linear;
  /*top: 130px;*/
  z-index: 1000;
  
  /* == */
  left:280px;
  top: 0px;
  border-left: 4px solid #e67e22;
  /* == */
}
.mcd-menu li ul:before {
  content: "";
  position: absolute;
  /*top: -8px;
  left: 23%;
  border-bottom: 5px solid #e67e22;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;*/
  
  /* == */
  top: 25px;
  left: -9px;
  border-right: 5px solid #e67e22;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  /* == */
}
.mcd-menu li:hover > ul,
.mcd-menu li ul li:hover > ul {
  display: block;
  opacity: 1;
  visibility: visible;
  /*top: 100px;*/
  
  /* == */
  left:250px;
  /* == */
}
/*.mcd-menu li ul li {
  float: none;
}*/
.mcd-menu li ul li a {
  padding: 10px;
  text-align: left;
  border: 0;
  border-bottom: 1px solid #EEE;
  
  /* == */
  height: auto;
  /* == */
}
.mcd-menu li ul li a i {
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 0 0;
}
.mcd-menu li ul li ul {
  left: 230px;
  top: 0;
  border: 0;
  border-left: 4px solid #e67e22;
}  
.mcd-menu li ul li ul:before {
  content: "";
  position: absolute;
  top: 15px;
  /*left: -14px;*/
  /* == */
  left: -9px;
  /* == */
  border-right: 5px solid #e67e22;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
}
.mcd-menu li ul li:hover > ul {
  top: 0px;
  left: 200px;
}



/*.mcd-menu li.float {
  float: right;
}*/
.mcd-menu li a.search {
  /*padding: 29px 20px 30px 10px;*/
  padding: 10px 10px 15px 10px;
  clear: both;
}
.mcd-menu li a.search i {
  margin: 0;
  display: inline-block;
  font-size: 18px;
}
.mcd-menu li a.search input {
  border: 1px solid #EEE;
  padding: 10px;
  background: #FFF;
  outline: none;
  color: #777;
  
  /* == */
  width:170px;
  float:left;
  /* == */
}
.mcd-menu li a.search button {
  border: 1px solid #e67e22;
  /*padding: 10px;*/
  background: #e67e22;
  outline: none;
  color: #FFF;
  margin-left: -4px;
  
  /* == */
  float:left;
  padding: 10px 10px 11px 10px;
  /* == */
}
.mcd-menu li a.search input:focus {
  border: 1px solid #e67e22;
}


.search-mobile { 
	display:none !important;
	background:#e67e22;
	border-left:1px solid #e67e22;
	border-radius:0 3px 3px 0;
}
.search-mobile i { 
	color:#FFF; 
	margin:0 !important;
}


@media only screen and (min-width: 960px) and (max-width: 1199px) {
    .mcd-menu {
		/* margin-left:10px; */
	}
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .mcd-menu {
		/* width: 200px; */
	}
	.mcd-menu li a {
		height:30px;
	}
	.mcd-menu li a i {
		font-size: 22px;
	}
	.mcd-menu li a strong {
		font-size: 12px;
	}
	.mcd-menu li a small {
		font-size: 10px;
	}	
	.mcd-menu li a.search input {
		width: 120px;
		font-size: 12px;
	}
	.mcd-menu li a.search buton{
		padding: 8px 10px 9px 10px;
	}
	.mcd-menu li > ul {
		min-width:180px;
	}
	.mcd-menu li:hover > ul {
		min-width:180px;
		left:200px;
	}
	.mcd-menu li ul li > ul, .mcd-menu li ul li ul li > ul {
		min-width:150px;
	}
	.mcd-menu li ul li:hover > ul {
		left:180px;
		min-width:150px;
	}
	.mcd-menu li ul li ul li:hover > ul {
		left:150px;
		min-width:150px;
	}
	.mcd-menu li ul a {
		font-size:12px;
	}
	.mcd-menu li ul a i {
		font-size:14px;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	
	.mcd-menu { 
    width: 50px;
   
	}
	.mcd-menu li a { 
		position: relative;
		padding: 12px 16px;
		height:20px;
	}
	.mcd-menu li a small { 
		display: none;
	}
	.mcd-menu li a strong { 
		display: none;
	}
	.mcd-menu li a:hover strong,.mcd-menu li a.active strong {
		display:block;
		font-size:10px;
		padding:3px 0;
		position:absolute;
		bottom:0px;
		left:0;
		background:#e67e22;
		color:#FFF;
		min-width:100%;
		text-transform:lowercase;
		font-weight:normal;
		text-align:center;
	}
	.mcd-menu li .search { 
		display: none;
	}
	
	.mcd-menu li > ul {
		min-width:180px;
		left:70px;
	}
	.mcd-menu li:hover > ul {
		min-width:180px;
		left:50px;
	}
	.mcd-menu li ul li > ul, .mcd-menu li ul li ul li > ul {
		min-width:150px;
	}
	.mcd-menu li ul li:hover > ul {
		left:180px;
		min-width:150px;
	}
	.mcd-menu li ul li ul li > ul {
		left:35px;
		top: 45px;
		border:0;
		border-top:4px solid #e67e22;
	}
	.mcd-menu li ul li ul li > ul:before {
		left:30px;
		top: -9px;
		border:0;
		border-bottom:5px solid #e67e22;
		border-left:5px solid transparent;
		border-right:5px solid transparent;
	}
	.mcd-menu li ul li ul li:hover > ul {
		left:30px;
		min-width:150px;
		top: 35px;
	}
	.mcd-menu li ul a {
		font-size:12px;
	}
	.mcd-menu li ul a i {
		font-size:14px;
	}
	
}

@media only screen and (max-width: 479px) {
    .mcd-menu { 
		width: 50px;
	}
	.mcd-menu li a { 
		position: relative;
		padding: 12px 16px;
		height:20px;
	}
	.mcd-menu li a small { 
		display: none;
	}
	.mcd-menu li a strong { 
		display: none;
	}
	.mcd-menu li a:hover strong,.mcd-menu li a.active strong {
		display:block;
		font-size:10px;
		padding:3px 0;
		position:absolute;
		bottom:0px;
		left:0;
		background:#e67e22;
		color:#FFF;
		min-width:100%;
		text-transform:lowercase;
		font-weight:normal;
		text-align:center;
	}
	.mcd-menu li .search { 
		display: none;
	}
	
	.mcd-menu li > ul {
		min-width:180px;
		left:70px;
	}
	.mcd-menu li:hover > ul {
		min-width:180px;
		left:50px;
	}
	.mcd-menu li ul li > ul, .mcd-menu li ul li ul li > ul {
		min-width:150px;
	}
	.mcd-menu li ul li:hover > ul {
		left:180px;
		min-width:150px;
	}
	.mcd-menu li ul li ul li > ul {
		left:35px;
		top: 45px;
		border:0;
		border-top:4px solid #e67e22;
	}
	.mcd-menu li ul li ul li > ul:before {
		left:30px;
		top: -9px;
		border:0;
		border-bottom:5px solid #e67e22;
		border-left:5px solid transparent;
		border-right:5px solid transparent;
	}
	.mcd-menu li ul li ul li:hover > ul {
		left:30px;
		min-width:150px;
		top: 35px;
	}
	.mcd-menu li ul a {
		font-size:12px;
	}
	.mcd-menu li ul a i {
		font-size:14px;
	}
    
}
.open>.dropdown-menu {
  display: block;
}

  /*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsImZpbGUiOiJleGFtcGxlLmJ1bmRsZS5jc3MiLCJzb3VyY2VSb290IjoiIn0=*/
  

body {
  color: #777;
}

.pure-img-responsive {
  max-width: 100%;
  height: auto;
}

/*
Add transition to containers so they can push in and out.
*/
#layout,
#menu,
.menu-link {
  transition: all 0.2s ease-out;
}

/*
This is the parent `<div>` that contains the menu and the content area.
*/
#layout {
  position: relative;
  left: 0;
  padding-left: 0;
}
  #layout.active #menu {
      left: 150px;
      width: 150px;
  }

  #layout.active .menu-link {
      left: 150px;
  }
/*
The content `<div>` is where all your content goes.
*/
.content {
  margin: 0 auto;
  padding: 0 2em;
  max-width: 800px;
  margin-bottom: 50px;
  line-height: 1.6em;
}

.header {
   margin: 0;
   color: #333;
   text-align: center;
   padding: 1.5em 2em 0;
   border-bottom: 1px solid #eee;
   background-color: blue;
   height: 50px;
}
  .header h1 {
      margin: 0.2em 0;
      font-size: 1em;
      font-weight: 300;
  }
   .header h2 {
      font-weight: 300;
      color: #ccc;
      padding: 0;
      margin-top: -7px;
  }

.content-subhead {
  margin: 50px 0 20px 0;
  font-weight: 300;
  color: #888;
}



/*
The `#menu` `<div>` is the parent `<div>` that contains the `.pure-menu` that
appears on the left side of the page.
*/

#menu {
  margin-left: -150px; /* "#menu" width */
  width: 150px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000; /* so the menu or its navicon stays above all content */
  background: #191818;
  overflow-y: auto;
}
  /*
  All anchors inside the menu should be styled like this.
  */
  #menu a {
      color: #999;
      border: none;
      padding: 0.6em 0 0.6em 0.6em;
  }

  /*
  Remove all background/borders, since we are applying them to #menu.
  */
   #menu .pure-menu,
   #menu .pure-menu ul {
      border: none;
      background: transparent;
  }

  /*
  Add that light border to separate items into groups.
  */
  #menu .pure-menu ul,
  #menu .pure-menu .menu-item-divided {
      border-top: 1px solid #333;
  }
      /*
      Change color of the anchor links on hover/focus.
      */
      #menu .pure-menu li a:hover,
      #menu .pure-menu li a:focus {
          background: #333;
      }

  /*
  This styles the selected menu item `<li>`.
  */
  #menu .pure-menu-selected,
  #menu .pure-menu-heading {
      background: #1f8dd6;
  }
      /*
      This styles a link within a selected menu item `<li>`.
      */
      #menu .pure-menu-selected a {
          color: #fff;
      }

  /*
  This styles the menu heading.
  */
  #menu .pure-menu-heading {
      font-size: 110%;
      color: #fff;
      margin: 0;
  }

/* -- Dynamic Button For Responsive Menu -------------------------------------*/

/*
The button to open/close the Menu is custom-made and not part of Pure. Here's
how it works:
*/

/*
`.menu-link` represents the responsive menu toggle that shows/hides on
small screens.
*/
.menu-link {
  position: fixed;
  display: block; /* show this only on small screens */
  top: 0;
  left: 0; /* "#menu width" */
  background: #000;
  background: rgba(0,0,0,0.7);
  font-size: 10px; /* change this value to increase/decrease button size */
  z-index: 10;
  width: 2em;
  height: auto;
  padding: 2.1em 1.6em;
}

  .menu-link:hover,
  .menu-link:focus {
      background: #000;
  }

  .menu-link span {
      position: relative;
      display: block;
  }

  .menu-link span,
  .menu-link span:before,
  .menu-link span:after {
      background-color: #fff;
      width: 100%;
      height: 0.2em;
  }

      .menu-link span:before,
      .menu-link span:after {
          position: absolute;
          margin-top: -0.6em;
          content: " ";
      }

      .menu-link span:after {
          margin-top: 0.6em;
      }


/* -- Responsive Styles (Media Queries) ------------------------------------- */

/*
Hides the menu at `48em`, but modify this based on your app's needs.
*/
@media (min-width: 48em) {

  .header,
  .content {
      padding-left: 2em;
      padding-right: 2em;
  }

  #layout {
      padding-left: 150px; /* left col width "#menu" */
      left: 0;
  }
  #menu {
      left: 150px;
  }

  .menu-link {
      position: fixed;
      left: 150px;
      display: none;
  }

  #layout.active .menu-link {
      left: 150px;
  }
}

@media (max-width: 48em) {
  /* Only apply this when the window is small. Otherwise, the following
  case results in extra padding on the left:
      * Make the window small.
      * Tap the menu to trigger the active state.
      * Make the window large again.
  */
  #layout.active {
      position: relative;
      left: 150px;
  }
}
#config-tool.open {
  right: 0;
}

#config-tool {
  position: fixed;
  right: -200px;
  top: 180px;
  width: 200px;
  z-index: 1000;
  transition: all 0.2s ease-in-out 0s;
  background: #fff;
  box-shadow: 5px 3px 13px 2px rgba(13, 7, 5, 0.5);
}

#config-tool-cog {
    cursor: pointer;
    left: -50px;
    padding: 10px 0;
    position: absolute;
    text-align: center;
    width: 50px;
    background-color: rgb(229, 229, 233);
}

#config-tool-options {
  height: 200px;
  padding: 10px;
}
